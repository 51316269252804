import React from 'react';
import cx from 'classnames';
import Breadcrumb from './SearchBreadcrumbs';
import * as SearchResults from '@elevio/kb-kit/lib/components/searchResults';

function SearchResult({ className, image }) {
  return (
  	<li>
	    <SearchResults.ArticleLink className={'search-result'}>
		    <SearchResults.ArticleTitle />
		    <small><SearchResults.Snippet className="article-link-summary" /></small>
	    </SearchResults.ArticleLink>
    </li>
  );
}

export default SearchResult;
