import * as React from 'react';
import { useCategory, useTranslation } from '@elevio/kb-kit/lib/hooks';
import * as Category from '@elevio/kb-kit/lib/components/category';
import * as Categories from '@elevio/kb-kit/lib/components/categories';
import * as Search from '@elevio/kb-kit/lib/components/search';
import * as Article from '@elevio/kb-kit/lib/components/article';
import * as Articles from '@elevio/kb-kit/lib/components/articles';
import PageLayout from '../components/layouts/Page';
import Header from '../components/Header';
import Footer from '../components/Footer';
import WithAsideLayout, {
  Aside,
  Main,
  MainHeader,
  MainBody,
} from '../components/layouts/WithAside';
import Breadcrumb from '../components/PageBreadcrumbs';
import CategoryList from '../components/CategoryList';
import CategoryBlock from '../components/CategoryBlock';
import { Summary } from '@elevio/kb-kit/lib/components/article';

function Page() {
  const { t } = useTranslation();

  const category = useCategory();

  const subCategoriesCount = (category.subCategories || []).length;
  const currentId = category.id;

  return (
    <PageLayout header={<Header showSearch={true} />} footer={<Footer />}>
      <div className="add-bs">
        <section className="hero hero-product">
          <Breadcrumb />

          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12">
                <div className="content">
                  <h1>
                    <Category.Title />
                  </h1>
                </div>
              </div>
            </div>

            <ul className="category-list">
              <Categories.Loop parentCategoryId={'root'}>
                <>
                  <Category.IsActive>
                    <li className="current-menu-item">
                      <Category.Link>
                        <Category.Title />
                      </Category.Link>
                    </li>
                  </Category.IsActive>

                  <Category.IsNotActive>
                    <li>
                      <Category.Link>
                        <Category.Title />
                      </Category.Link>
                    </li>
                  </Category.IsNotActive>
                </>
              </Categories.Loop>
            </ul>
          </div>
        </section>

        <div className="ptb100 pt60">
          <div className="container">
            <div className="row p40 b-30">
              <div className="col-lg b30">
                <div className="category-text">
                  <div className="row b-30">
                    {subCategoriesCount > 0 && (
                      <Categories.Loop>
                        <CategoryBlock />
                      </Categories.Loop>
                    )}

                    {subCategoriesCount === 0 && (
                      <div className="col-12">
                        <Articles.Loop>
                          <div className="b30 pr-box sub-cat no-lift">
                            <Article.Link>
                              <Article.Title />
                            </Article.Link>
                            <Article.Summary />

                            <Article.Link className={'but'}>
                              Read more
                            </Article.Link>
                          </div>
                        </Articles.Loop>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*<WithAsideLayout>*/}
      {/*  <Main>*/}
      {/*    <MainHeader>*/}
      {/*      <h1 className="main-title" data-testid="category-title">*/}
      {/*        <Category.Title />*/}
      {/*      </h1>*/}
      {/*    </MainHeader>*/}

      {/*    <MainBody data-testid="category-body">*/}
      {/*      <Articles.Loop>*/}
      {/*        <Article.Link className="article-link" data-testid="article-link">*/}
      {/*          <div>*/}
      {/*            <h3 className="article-link-title">*/}
      {/*              <Article.Title />*/}
      {/*            </h3>*/}
      {/*            <div className="article-link-summary">*/}
      {/*              <Article.Summary />*/}
      {/*            </div>*/}
      {/*          </div>*/}
      {/*        </Article.Link>*/}
      {/*      </Articles.Loop>*/}
      {/*    </MainBody>*/}
      {/*  </Main>*/}

      {/*  <Aside>*/}
      {/*    <Search.Input*/}
      {/*      className="sidebar-search"*/}
      {/*      placeholder={t('search.shortplaceholder', 'Search...')}*/}
      {/*      data-testid="category-search-input"*/}
      {/*    />*/}
      {/*    <CategoryList />*/}
      {/*  </Aside>*/}
      {/*</WithAsideLayout>*/}
    </PageLayout>
  );
}

export default Page;
