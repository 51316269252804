import React, { useState } from 'react';
import cx from 'classnames';
import { Trans } from '@elevio/kb-kit/lib/components/trans';
import * as Search from '@elevio/kb-kit/lib/components/search';
import * as Categories from '@elevio/kb-kit/lib/components/categories';
import { useLanguages, useTranslation } from '@elevio/kb-kit/lib/hooks';
import { Logo } from './Logo';
import LoginLogout from './LoginLogout';
import LanguagePicker from './LanguagePicker';

function Header({ children, className, showSearch }) {
  const [displaySearchBar, setDisplaySearchBar] = useState(false);
  const [displayMobileMenu, setDisplayMobileMenu] = useState(false);
  const { t } = useTranslation();
  const { supportedLanguages, selectedLanguage, setCurrentLanguageId } =
    useLanguages();
  return (
    <header className="mega-menu">
      {/* <div className="container-fluid"> */}
      <div className="container bigger">
        <div className="row">
          <div className="col-lg-3 d-flex justify-content-between">
            <div className=" header-logo fit-logo-img add-header-height  logo-has-sticky">
              <a href="/" className="logo">
                <svg
                  width="120"
                  height="31"
                  viewBox="0 0 120 31"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M20.6728 29.8184H20.974C22.9237 29.7988 24.8514 29.4036 26.6517 28.6569C28.5108 27.8908 30.2033 26.766 31.6264 25.346C33.4144 23.5648 34.7175 21.3616 35.4131 18.9412C35.2466 19.2437 35.058 19.5316 34.8547 19.8098V20.005H34.7102C34.4848 20.2953 34.2448 20.5735 33.9827 20.8346C32.3367 22.4669 30.1151 23.3867 27.7931 23.3965C25.6989 23.416 23.683 22.6011 22.1963 21.1347L15.8548 14.8177L21.8974 8.80087C23.4944 7.19784 25.6719 6.29995 27.9401 6.30727C30.2106 6.30239 32.3832 7.20028 33.9851 8.80087C34.2693 9.08146 34.5289 9.38401 34.7689 9.69876H34.8571V9.82319C35.0433 10.0794 35.2196 10.3429 35.3764 10.6186C34.6636 8.25433 33.3801 6.09987 31.6288 4.35045C28.8365 1.5543 25.0376 -0.00968885 21.0818 7.08383e-05C17.1261 -0.0121288 13.332 1.55186 10.5397 4.34557L0 14.8519L11.0614 25.8706C13.621 28.3934 17.0746 29.811 20.6752 29.8184H20.6728Z"
                    fill="#EA2384"
                  />
                  <path
                    d="M76.2074 8.51542L76.031 8.67645C74.6838 10.094 73.9368 11.9728 73.9417 13.9247V23.194H77.2385V13.9101C77.2508 12.7536 77.7186 11.6458 78.5367 10.826C79.3597 9.99889 80.4815 9.53043 81.6499 9.53043C82.8109 9.54263 83.9229 10.0062 84.7459 10.8236C85.5787 11.6434 86.0465 12.7584 86.0465 13.9223V23.2721H89.3434V13.9101C89.3385 11.8801 88.5277 9.93545 87.0851 8.50322C84.0772 5.51432 79.2078 5.5192 76.2074 8.51542Z"
                    fill="white"
                  />
                  <path
                    d="M65.6513 27.4223C66.4253 26.5025 67.0254 25.4533 67.4271 24.3236L73.6289 6.72205H70.1361L65.884 18.7923L60.7697 6.72205H57.1152L64.2429 23.3721L64.1425 23.6112C63.8682 24.27 63.491 24.8824 63.0256 25.424C62.1144 26.4854 60.8211 27.1491 59.4226 27.2735V30.5625C61.8499 30.4356 64.1107 29.2938 65.6513 27.4223Z"
                    fill="white"
                  />
                  <path
                    d="M108.599 12.8463V12.778C108.226 11.336 107.487 10.0136 106.453 8.93513C103.12 5.46556 97.5913 5.34357 94.1082 8.6643L93.9637 8.80825C92.3324 10.4406 91.4164 12.6487 91.4188 14.952C91.4188 19.7611 95.3329 23.6601 100.161 23.6601C102.627 23.6601 104.981 22.6182 106.627 20.7883L104.018 18.7509C102.997 19.7977 101.591 20.3832 100.126 20.371C98.1179 20.3686 96.2711 19.2658 95.3231 17.5017L94.8382 16.5989H108.707C108.809 16.0524 108.861 15.4985 108.858 14.9447C108.871 14.2615 108.797 13.5807 108.638 12.9171L108.601 12.8439L108.599 12.8463ZM94.8284 13.3148L95.3133 12.412C96.2686 10.648 98.1179 9.54511 100.131 9.53779L100.126 9.53047C101.118 9.53047 102.091 9.8013 102.938 10.3112C103.776 10.8212 104.464 11.5458 104.932 12.4071L105.422 13.3123H94.8259L94.8284 13.3148Z"
                    fill="white"
                  />
                  <path
                    d="M46.5705 19.6537C43.9325 18.1653 43.0042 14.8275 44.4983 12.1997C44.8559 11.5727 45.336 11.0212 45.9092 10.5772C48.3047 8.72772 51.7534 9.16203 53.61 11.5507L56.4832 9.80616C54.8274 7.48336 52.1478 6.10236 49.2893 6.09748C44.4077 6.09016 40.4471 10.0258 40.4373 14.8885C40.4299 19.7513 44.3808 23.6966 49.2624 23.7064H49.2673C52.2237 23.7064 54.9841 22.2302 56.6228 19.7757L53.7129 18.1214C52.0694 20.3661 48.9979 21.0274 46.5705 19.6561V19.6537Z"
                    fill="white"
                  />
                  <path
                    d="M115.958 2.2619L112.661 3.25739V6.72208H109.568V10.0062H112.661V18.2483C112.666 21.2372 115.091 23.66 118.089 23.6771H119.997V20.3978H118.109C116.923 20.3856 115.968 19.4316 115.958 18.2507V10.0062H119.997V6.71964H115.958V2.2619Z"
                    fill="white"
                  />
                  <path
                    d="M34.7685 9.69641C34.7979 9.73789 34.8273 9.77937 34.8567 9.82085V9.69641H34.7685Z"
                    fill="#EA2384"
                  />
                  <path
                    d="M34.8569 20.0026V19.8074C34.8104 19.8733 34.7614 19.9368 34.7124 20.0026H34.8569Z"
                    fill="#EA2384"
                  />
                  <path
                    d="M29.7083 9.69641H29.6568C26.8204 9.71105 24.5229 11.9973 24.5107 14.8227V14.8739C24.5254 17.7091 26.8351 20.0027 29.6838 20.0027C32.5324 20.0027 34.8569 17.6945 34.8569 14.8495C34.8569 12.0046 32.5544 9.71105 29.7083 9.69641Z"
                    fill="#EA2384"
                  />
                </svg>
              </a>
            </div>
            <button
              className="d-lg-none hamburger hamburger--squeeze"
              type="button"
            >
              <span className="hamburger-box">
                {' '}
                <span className="hamburger-inner" />{' '}
              </span>
            </button>
          </div>

          <div className="col-lg-6 text-center d-flex justify-content-center">
            <div className="form-style-2">
              <div className="hs-form">
                <Search.Input
                  placeholder={'What do you need help with?'}
                  data-testid="search-input"
                  className="hero-search"
                  style={{ cursor: 'auto' }}
                />
              </div>
            </div>
          </div>

          <div className="col-md-3 align-items-center justify-content-end d-flex">
            <div className="menu-mega-menu-container">
              <ul id="menu-mega-menu" className="menu">
                <li
                  id="menu-item-29721"
                  className="trial-button menu-item menu-item-type-custom menu-item-object-custom menu-item-29721"
                >
                  <LoginLogout className="nav-desktop-menu-link" />
                  {supportedLanguages.length > 1 && (
                    <LanguagePicker
                      selectedLanguageId={selectedLanguage.id}
                      setCurrentLanguageId={setCurrentLanguageId}
                      supportedLanguages={supportedLanguages}
                    />
                  )}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
