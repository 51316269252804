import * as React from 'react';
import * as Article from '@elevio/kb-kit/lib/components/article';
import * as Search from '@elevio/kb-kit/lib/components/search';
import { useCategory, useTranslation } from '@elevio/kb-kit/lib/hooks';
import PageLayout from '../components/layouts/Page';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { SRLWrapper } from 'simple-react-lightbox';
import WithAsideLayout, {
  Aside,
  Main,
  MainHeader,
  MainBody,
} from '../components/layouts/WithAside';
import CategoryList from '../components/CategoryList';
import ArticleList from '../components/ArticleList';
import Breadcrumb from '../components/PageBreadcrumbs';
import ArticleMeta from '../components/ArticleMeta';
import ArticleActions from '../components/ArticleActions';
import ArticleFeedback from '../components/ArticleFeedback';
import * as Category from '@elevio/kb-kit/lib/components/category';
import * as Categories from '@elevio/kb-kit/lib/components/categories';
import * as Articles from '@elevio/kb-kit/lib/components/articles';

function Page() {
  const { t } = useTranslation();
  const cat = useCategory();

  // run code after the component is mounted
  React.useEffect(() => {
    const elevioArticleLinks = document.querySelectorAll(
      'span[data-elevio-article]'
    );

    if (elevioArticleLinks.length) {
      elevioArticleLinks.forEach((article) => {
        const articleId = article.getAttribute('data-elevio-article');
        const articleTitle = article.innerText;
        const anchor = document.createElement('a');
        anchor.setAttribute('href', `/articles/${articleId}`);
        anchor.innerText = articleTitle;
        article.replaceWith(anchor);
      });
    }
  }, []);

  const lightBox = {
    showDownloadButton: false,
    showFullscreenButton: false,
    showNextButton: false,
  };

  return (
    <PageLayout header={<Header showSearch={true} />} footer={<Footer />}>
      <section className="hero hero-product">
        <Breadcrumb className="breadcrumbs-header" />

        <div className="container bigger">
          <div className="row justify-content-center">
            <div className="col-12">
              <div className="content">
                {/*<h1><Category.Title /></h1>*/}
                {/*<p className="p1">Lorem ipsum dolor sit amet, consectetur adipiscing elit. </p>*/}
              </div>
            </div>
          </div>

          <ul className="category-list">
            <Categories.Loop parentCategoryId={'root'}>
              <>
                <Category.IsActive>
                  <li className="current-menu-item">
                    <Category.Link>
                      <Category.Title />
                    </Category.Link>
                  </li>
                </Category.IsActive>

                <Category.IsNotActive>
                  <li>
                    <Category.Link>
                      <Category.Title />
                    </Category.Link>
                  </li>
                </Category.IsNotActive>
              </>
            </Categories.Loop>
          </ul>
        </div>
      </section>

      <div className="ptb100 pt30">
        <div className="container">
          <div className="row p40 b-30">
            <div className="col-lg-3 b30">
              <nav className="left-menu-nav">
                <ul className="left-menu-nav-list">
                  <Articles.Loop parentCategoryId={cat.parentCategory}>
                    <li>
                      <Article.Link>
                        <Article.Title />
                      </Article.Link>
                    </li>
                  </Articles.Loop>
                </ul>
              </nav>
            </div>
            <div className="col-lg b30">
              <div className="category-text single-article">
                <SRLWrapper>
                  <h2>
                    <Article.Title />
                  </h2>
                  <Article.Body />
                </SRLWrapper>

                <ArticleFeedback />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*<WithAsideLayout>*/}
      {/*  <Main>*/}
      {/*    <MainHeader>*/}
      {/*      <ArticleActions />*/}
      {/*      <h1 className="main-title" data-testid="article-title">*/}
      {/*        <Article.Title />*/}
      {/*      </h1>*/}
      {/*      <ArticleMeta />*/}
      {/*    </MainHeader>*/}

      {/*    <MainBody>*/}
      {/*      <Article.Body />*/}
      {/*    </MainBody>*/}
      {/*    <ArticleFeedback />*/}
      {/*  </Main>*/}

      {/*  <Aside>*/}
      {/*    <Search.Input*/}
      {/*      className="sidebar-search"*/}
      {/*      placeholder={t('search.shortplaceholder', 'Search...')}*/}
      {/*      data-testid="article-search-input"*/}
      {/*    />*/}
      {/*    <CategoryList />*/}
      {/*    <ArticleList />*/}
      {/*  </Aside>*/}
      {/*</WithAsideLayout>*/}
    </PageLayout>
  );
}

export default Page;
