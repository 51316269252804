import * as React from 'react';
import * as Search from '@elevio/kb-kit/lib/components/search';
import * as SearchResults from '@elevio/kb-kit/lib/components/searchResults';
import { HomeLink } from '@elevio/kb-kit/lib/components/meta';
import { useSearchResults, useTranslation } from '@elevio/kb-kit/lib/hooks';
import { Trans } from '@elevio/kb-kit/lib/components/trans';
import PageLayout from '../components/layouts/Page';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { MainCentered } from '../components/layouts/Main';
import SearchResult from '../components/SearchResult';
import SearchMore from '../components/SearchMore';
import * as Category from "@elevio/kb-kit/lib/components/category";
import * as Categories from "@elevio/kb-kit/lib/components/categories";
import Breadcrumb from "../components/PageBreadcrumbs";
import CategoryBlock from "../components/CategoryBlock";
import * as Articles from "@elevio/kb-kit/lib/components/articles";
import * as Article from "@elevio/kb-kit/lib/components/article";

const Page = () => {
  const { t } = useTranslation();
  const { searchTerm, totalCount } = useSearchResults();
  return (
	  <PageLayout header={<Header />} footer={<Footer />}>
		  <div className="add-bs">
			  <section className="hero hero-product">
				  <div className="container bigger">
					  <div className="row justify-content-center">
						  <div className="col-12">
							  <div className="content">
								  <h1>Search Results</h1>
								  {/*<p className="p1">Lorem ipsum dolor sit amet, consectetur adipiscing elit. </p>*/}
							  </div>
						  </div>
					  </div>

					  <ul className="category-list">
						  <Categories.Loop parentCategoryId={'root'}>
							  <>
								  <Category.IsActive>
									  <li className="current-menu-item"><Category.Link><Category.Title/></Category.Link></li>
								  </Category.IsActive>

								  <Category.IsNotActive>
									  <li><Category.Link><Category.Title/></Category.Link></li>
								  </Category.IsNotActive>
							  </>
						  </Categories.Loop>
					  </ul>
				  </div>
			  </section>

			  <Breadcrumb />

			  <div className="ptb100 pt20">
				  <div className="container">
					  <div className="row p40 b-30">
						  <div className="col-lg b30">
							  <div className="category-text">
								  <div className="row b-30">
									  <div className="col-12 b30">

										  <ul className={'active'}>
											  <SearchResults.LoopResults>
												  <SearchResult />
											  </SearchResults.LoopResults>
										  </ul>
									  </div>
								  </div>
							  </div>
						  </div>
					  </div>
				  </div>
			  </div>
		  </div>
	  </PageLayout>
  );
};

export default Page;
