import React from 'react';
import cx from 'classnames';
import * as Meta from '@elevio/kb-kit/lib/components/meta';
import * as Category from '@elevio/kb-kit/lib/components/category';
import * as Article from '@elevio/kb-kit/lib/components/article';
import { IBreadCrumb } from '@elevio/kb-kit/lib/types';

function Breadcrumb({ className, crumbs, noLinks }) {
  return (
    <React.Fragment>
      <div className="breadcrumbs-area">
        {/* <div className="container-fluid"> */}
        <div className="container bigger">
          <ul className="breadcrumbs-list">
            <li>
              <a href="/">
                <svg
                  width="12"
                  height="14"
                  viewBox="0 0 12 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.6133 5.78665L6.47335 0.53332C6.34817 0.407111 6.17777 0.336121 6.00002 0.336121C5.82226 0.336121 5.65186 0.407111 5.52668 0.53332L0.386683 5.79332C0.135453 6.04658 -0.00381536 6.38994 1.59745e-05 6.74665V12.3333C-0.00105665 13.042 0.552421 13.6277 1.26002 13.6667H3.33335V7.66665C3.33335 7.29846 3.63183 6.99999 4.00002 6.99999H8.00002C8.36821 6.99999 8.66668 7.29846 8.66668 7.66665V13.6667H10.74C11.4476 13.6277 12.0011 13.042 12 12.3333V6.74665C12.0005 6.38861 11.8619 6.04438 11.6133 5.78665Z"
                    fill="white"
                  />
                </svg>
              </a>
            </li>
            <li>
              <a href="/en/categories/6-user-guide">User Guide</a>
            </li>
            <li>
              <a href="/en/categories/3-releases">Release Notes</a>
            </li>
            <li>
              <a href="/en/categories/15-installation">Installation</a>
            </li>
            <li>
              <a href="/en/categories/49-cynet-360-mobile">Mobile</a>
            </li>
            <li>
              <a href="https://help.api.cynet.com/">API Reference</a>
            </li>
          </ul>
        </div>
      </div>

      <div className="alt-breadcrumbs-area">
        <div className="container">
          <ul className="breadcrumbs-list alt">
            {crumbs.map((crumb, index) => (
              <Crumb
                key={index}
                crumb={crumb}
                className="breadcrumbs-link"
                noLinks={noLinks}
              />
            ))}
          </ul>
        </div>
      </div>
    </React.Fragment>
  );
}

function Crumb({ noLinks, crumb, className }) {
  if (noLinks) {
    return <li className={className}>{crumb.title}</li>;
  }

  if (crumb.linkType === 'article') {
    return (
      <li>
        <Article.Link articleId={crumb.linkId} className={className}>
          {crumb.title}
        </Article.Link>
      </li>
    );
  }

  if (crumb.linkType === 'category') {
    return (
      <li>
        <Category.Link categoryId={crumb.linkId} className={className}>
          {crumb.title}
        </Category.Link>
      </li>
    );
  }

  return (
    <li>
      <Meta.HomeLink className={className}>{crumb.title}</Meta.HomeLink>
    </li>
  );
}

export default Breadcrumb;
