import React, { useState } from 'react';
import cx from 'classnames';
import { Trans } from '@elevio/kb-kit/lib/components/trans';
import { useArticles, useCategory } from '@elevio/kb-kit/lib/hooks';
import * as Category from '@elevio/kb-kit/lib/components/category';
import * as Meta from '@elevio/kb-kit/lib/components/meta';
import * as Articles from '@elevio/kb-kit/lib/components/articles';
import * as Article from '@elevio/kb-kit/lib/components/article';

const CategoryBlock = ({ categoryId, className }) => {
  const { totalCount } = useArticles({
    categoryId,
  });

  const [showing, setShowing] = useState(false);

  return (

    <div className="col-lg-6 b30">
      <div className="pr-box no-lift">
        <h3><Category.Title/></h3>

        <ul className={showing ? 'active' : ''}>
          <Articles.Loop>
            <li><Article.Link><Article.Title/></Article.Link></li>
          </Articles.Loop>
        </ul>

        {totalCount > 5 &&
          <button onClick={() => setShowing(!showing)} className="view-more">View {showing ? 'less' : 'more'}</button>
        }
      </div>
    </div>
  );
}

export default CategoryBlock;
